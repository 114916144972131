<template>
    <b-form-group
        :id="'fieldset-horizontal-label-'+ value.dataNamePrefix"
        label-cols-sm="4"
        label-cols-lg="3"
        :label-size="size"
        :label="inputLabel"
        :invalid-feedback="invalidFeedback"
        :state="validationResult.all"
    >
        <b-row no-gutters>

            <b-col cols="12">               
                <b-form-group
                    id="fieldset-adrs-lineone"
                    description="Line 1"
                    label-for="adrs-lineone"
                >
                    <b-form-input id="adrs-lineone" v-model="inputValue.lineOne" :size="size" trim @input="touch($event,'lineOne')"></b-form-input>
                </b-form-group>
            </b-col>

            <b-col cols="12">

                <b-form-group
                    id="fieldset-adrs-linetwo"
                    description="Line 2"
                    label-for="adrs-linetwo"
                >
                    <b-form-input id="adrs-linetwo" v-model="inputValue.lineTwo" :size="size" trim @input="touch($event,'lineTwo')"></b-form-input>
                </b-form-group>
            </b-col>

            <b-col cols="8" sm="7" xl="9">
                <!-- City -->
                <b-form-group
                    id="fieldset-adrs-city"
                    description="City"
                    label-for="adrs-city"
                >
                    <b-form-input id="adrs-city" :size="size" v-model="inputValue.city" @input="touch($event,'city')" trim></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="4" sm="2" xl="1">
                <!-- State -->
                <b-form-group
                    id="fieldset-adrs-state"
                    description="State"
                    label-for="adrs-state"
                >
                    <b-form-input id="adrs-state" :size="size" v-model="inputValue.state" @input="touch($event,'state')" trim></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="5" sm="3" xl="2">
                <!-- Zip -->
                <b-form-group
                    id="fieldset-adrs-zip"
                    description="Zip"
                    label-for="adrs-zip"
                >
                    <b-form-input id="adrs-zip" :size="size" v-model="inputValue.zip" @input="touch($event,'zip')" type="number" trim></b-form-input>
                </b-form-group>
            </b-col>

            
        </b-row>
    </b-form-group>
</template>

<script>
const cloneDeep = require('lodash.clonedeep');
const axios = require('axios');
const _ = require('underscore');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });


export default {
    name: 'Formaddressinputwithparsing',
    components:{        
    },
    props:{
        
        inputLabel: String,      
        parentFormID: String,
        value: {
            type: [Object],
            default: (val) => {
                return {
                    lineOne: null,
                    lineTwo: null,
                    city: null,
                    state: null,
                    zip: null,
                }
            }
        },
        isSite: {
            type: Boolean,
            default: () => {return false} 
        },
        size: {
            type: String,
            validator: (val) =>{
                // The value must match one of these strings
                return ['sm', 'md', 'lg'].indexOf(val) != -1
            },
            default: () => {return 'md'}
        },
        
        index: {
            type: Number,
            default: () => {return -1;}
        },        
    },
    data(){
        return{
            //parentFormID: 'b78f4b32-1964-4ace-985e-850748f5d961',
            keyList: ['lineOne', 'lineTwo', 'city'],
            inputValue: {
                lineOne: null,
                lineTwo: null,
                city: null,
                state: null,
                zip: null,
            },
            originalValue: {},
            useParser: 'js',
            showExtended: false,
            options: [],
            isDirty: false,
            count: 1
        }
    },
    methods: {
        touch(event, name) {
            // this.isDirty = true;
            var reportType = this.getReportType(name);
            var reportDataName = this.value.dataNamePrefix + this.getDataPostfix(name);

            var commitValue = {parentFormID: this.parentFormID, type: reportType, dataName: reportDataName, index: this.index, value: this.inputValue[name], valid: true, isDirty: true};
            console.log('Commit Value: ');
            console.log(commitValue);
            this.$store.commit('updateFormValue', commitValue );            
        },
        prependInput(){
            
        },
        getReportType(name){            
            return 'formtextregexinput';            
        },
        getDataPostfix(name){
            switch (name) {
                case 'lineOne':
                    return '_line_one';
                    
                case 'lineTwo':
                    return '_line_two';

                case 'city':
                    return '_city';

                case 'state':
                    return '_state';
                
                case "zip":
                    return "_zip_code";
                    
                default:
                    return null;
                    
            }
        }       
    },
    computed: {        
        concatenatedAddress: function(){
            
            return this.inputValue.lineOne + '\n' + 
                this.inputValue.lineTwo + '\n' + 
                this.inputValue.city + ', ' + this.state + ' ' + this.inputValue.zip;            
        },
        // a computed getter
        validationResult: function () {
            return {matchOptions: true, requirementFilled: true, all: true}        
        },
        invalidFeedback(){       
        }
    },
    mounted(){

        this.$store.commit('registerFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.value.dataNamePrefix + '_line_one', value: this.inputValue.lineOne, valid: this.validationResult.all, isDirty: this.isDirty} );
        this.$store.commit('registerFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.value.dataNamePrefix + '_line_two', value: this.inputValue.lineTwo, valid: this.validationResult.all, isDirty: this.isDirty} );
        this.$store.commit('registerFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.value.dataNamePrefix + '_city', value: this.inputValue.city, valid: this.validationResult.all, isDirty: this.isDirty} );
        this.$store.commit('registerFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.value.dataNamePrefix + '_state', value: this.inputValue.state, valid: this.validationResult.all, isDirty: this.isDirty} );
        this.$store.commit('registerFormValue', {parentFormID: this.parentFormID, type: this.$options.name, dataName: this.value.dataNamePrefix + '_zip_code', value: this.inputValue.zip, valid: this.validationResult.all, isDirty: this.isDirty} );
    },
    created(){
    },
    beforeDestroy(){
        //this.$store.commit('removeFormValue', {parentFormID: this.parentFormID, dataName: this.dataName} );
    },
    beforeMount (){
        console.log("Before Mount value is")
        //console.log(this.value)

        this.originalValue = cloneDeep(this.value);
        this.inputValue = this.value;
        this.inputValue.concat = this.concatenatedAddress;
    },
}
</script>

<style scoped>
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button:hover { 
  -webkit-appearance: none; 
  margin: 0; 
}
</style>


