<template>
    <div :id="(requestFormInspects == true) ? '' : 'fit-window'" ref="fitWindow">
        <div v-if="requestFormInspects">
            <b-row class="mt-3">
                <b-col></b-col>
                <b-col class="" cols="8">
                    <h2 class="text-center">Let's Get Started Building Forms</h2>
                    <hr>
                    <h3 class="text-center">What Would You Like To Do?</h3>
                </b-col>
                <b-col></b-col>
            </b-row>

            <b-row class="mt-3" v-if="selectedActionType == null">
                <b-col cols="2"></b-col>
                <b-col cols="3">
                    <b-button block size="lg" variant="primary" @click="selectedActionType = 'update';">Update An Existing Form</b-button>
                </b-col>
                <b-col></b-col>
                <b-col cols="3">
                    <b-button block size="lg" variant="primary" @click="selectedActionType = 'create';">Create A New Form</b-button>
                </b-col>
                <b-col cols="2"></b-col>
            </b-row>

            <b-row class="mt-3" v-if="selectedActionType == 'update'">
                <b-col cols="2"></b-col>
                <b-col cols="8">
                    <b-form-group 
                        :id="`init-formbuilder-load-form-selector-label`"
                        class="mb-2"
                        label-cols-sm="12"
                        label-cols-lg="12"
                        description="Required"
                        label="Select A Form To Edit"
                        label-class="align-self-center"
                        :label-for="'init-formbuilder-load-form-selector-value'"
                    >
                        <b-form-select  
                            :id="'init-formbuilder-load-form-selector-value'"
                            :options="allLoadableForms"
                            v-model="formLoadSelected"
                        ></b-form-select>
                    </b-form-group>
                    <b-button class="mr-2" variant="primary" :disabled='formLoadSelected == null' @click="completedUpdateSelection()">Start Editing</b-button>
                    <b-button variant="warning" @click="exitPage()">Cancel</b-button>
                </b-col>
                <b-col cols="2"></b-col>
            </b-row>

            <b-row class="mt-3" v-if="selectedActionType == 'create'">
                <b-col></b-col>
                <b-col cols="8">
                    <b-form>
                        <b-form-group
                            id="init-form-inspects-group"
                            label="What Does This Form Inspect?"
                            label-for="init-inspects"
                            class="mb-2"
                        >
                            <b-form-select  
                                id="init-inspects"
                                :options="inspectOptions"
                                v-model="formInspects"
                                :required="true"
                            ></b-form-select>
                        </b-form-group>
                    </b-form>
                    <b-button class="mr-2" variant="primary" :disabled='formInspects == null' @click="completedCreateSelection()">Okay, Let's Get At It!</b-button>
                    <b-button variant="warning" @click="exitPage()">Cancel</b-button>
                </b-col>
                <b-col></b-col>
            </b-row>
        </div>
        <b-nav v-if="!requestFormInspects" id="lb-sidebar" :class="(sidebarOpen) ? 'lb-sb-nav-open' : 'lb-sb-nav-closed'" vertical>
            <div class="my-1">
                
            </div>
            <b-tabs content-class="mt-3" id="lb-sb-tabs">
                    <b-tab title="Fields" active class="scrollable-veritically">
                        <draggable class="drag-drop-box bg-secondary w-100 h-75" v-model="discard" v-if="dragging" group="formfields" draggable=".draggable-item" handle=".draggable-item">
                            <div class="draggable-item">
                                Drop Here To Return Field To The Field List
                            </div>
                        </draggable>
                        <div :class="(dragging) ? 'd-none' : ''"> 
                            <div class="clearfix">
                                <div class="float-left"><span class="align-middle">Reusable Utilities</span></div>
                            </div>
                            <div v-for="(obj, index) in utilities" :key="`utilities-${index}`" class="ml-3 bg-dark text-white draggable-item">
                                    <div class="clearfix">
                                        <div class="float-left"><span class="align-middle">{{obj.inputLabel}}</span></div> 
                                        <div class="hoverable-add float-right" @click="copyResusableToForm(obj)"><i class="align-middle material-icons">double_arrow</i></div>
                                    </div>
                                </div>
                            <div v-for="(par, index) in availableGroups" :key="`parent-${index}`" class="">
                                <div class="clearfix">
                                    <div class="float-left"><span class="align-middle">{{par.name}}</span></div>
                                </div>
                                <div v-for="(obj, index) in fields.filter((fld)=>{ return fld.uiGroup == par.dataName; })" :key="`fields-${index}`" class="ml-3 bg-dark text-white draggable-item">
                                    <div class="clearfix">
                                        <div class="float-left"><span class="align-middle">{{obj.inputLabel}}</span></div> 
                                        <div class="hoverable-add float-right" @click="moveToForm(obj)"><i class="align-middle material-icons">double_arrow</i></div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </b-tab>
                    <b-tab title="Save">
                        <div class="mx-1">
                            <b-form-group
                                id="save-name-title"
                                description="This is the name displayed in the form list and at the top of the form when it is loaded. We recommend a short and sweet name, but we're not the boss of you..."
                                label="Form Name"
                                label-for="save-name"
                                
                            >
                                <b-form-input id="save-name" v-model="formName" required trim></b-form-input>
                            </b-form-group>
                            <b-form-group
                                id="save-desc-title"
                                description="(Optional) - Maybe helpful to keep notes in here about exactly what this list is for."
                                label="List Description"
                                label-for="save-desc"
                            >
                                <b-form-textarea id="save-desc" v-model="formDescription" trim></b-form-textarea>
                            </b-form-group>
                            <b-button v-if="formID == null"
                                :disabled="(formName != null && formInspects != '') ? false : true"
                                :variant="(formInspects != null && formInspects != '') ? 'success' : 'danger' "
                                @click="prepareForSave()"
                                block
                            >
                                Save List
                            </b-button>

                            <b-button v-if="formID != null"
                                :disabled="(formName != null) ? false : true"
                                :variant="(formName != null) ? 'success' : 'danger' "
                                @click="prepareForSave()"
                                block
                            >
                                Save Updates
                            </b-button>

                            <b-button v-if="formID != null"
                                :disabled="(formName != null) ? false : true"
                                :variant="(formName != null) ? 'success' : 'danger' "
                                @click="saveCopy()"
                                block
                            >
                                Save As Copy
                            </b-button>
                        </div>
                    </b-tab>
                    <b-tab title="Load" @click="fetchFormList()">
                        <div>
                            <b-form-group 
                                :id="`formbuilder-load-form-selector-label`"
                                label-cols-sm="12"
                                label-cols-lg="12"
                                description="Required"
                                label="Select Form To Load"
                                label-class="align-self-center"
                                :label-for="'formbuilder-load-form-selector-value'"
                            >
                                <b-form-select  
                                    :id="'formbuilder-load-form-selector-value'"
                                    :options="allLoadableForms"
                                    v-model="formLoadSelected"
                                ></b-form-select>
                            </b-form-group>
                            <b-button :disabled="(formLoadSelected == null) ? true : false" @click="loadForm()">Load Form</b-button>
                        </div>
                    </b-tab>
                    <b-tab class="scrollable-veritically">
                        <template #title>
                            <i class="material-icons smaller-mdi">build</i>
                        </template>
                        <b-button @click="togglePreview()">{{(previewing) ? 'Edit Form' : 'Preview Form'}}</b-button>
                        <br>
                        <b-button @click="setAllSize('sm')">Set All Size Small</b-button>
                        <b-button @click="setAllSize('md')">Set All Size Medium</b-button>
                        <b-button @click="setAllSize('lg')">Set All Size Large</b-button>
                    </b-tab>
                </b-tabs>
        </b-nav>
        <div v-if="!requestFormInspects" id="lb-sidebar-container" :class="(sidebarOpen) ? 'lb-sb-cont-open' : 'lb-sb-cont-closed'">
            <div class="centered-expand-control">
                <i v-if="sidebarOpen" class="material-icons drop-down xpander-icon xpander-icon-expanded" @click="toggleNav">arrow_back_ios</i>
                <i v-else class="material-icons drop-down xpander-icon xpander-icon-closed" @click="toggleNav">arrow_forward_ios</i>
            </div>
        </div>
        <div id="table-holder" :class="((sidebarOpen) ? 'lb-th-sb-open' : 'lb-th-sb-closed') + ((requestFormInspects) ? ' d-none': '')">
            <div id="full-form" class="p-2">
                <div class="mt-1">
                    <h4>Form Title</h4>
                </div>
                <div>
                    <textarea id="tinymce-subject-editor"></textarea>
                </div>
                <div class="mt-1">
                    <h4>Form Contents</h4>
                </div>
                <b-form>
                    <draggable v-model="inForm" @start="dragging=true" @end="dragging=false; checkForDiscards()" group="formfields" handle=".draggable-handle">
                        <div v-for="(comp, index) in inForm" :key="`main-${index}`">
                            <div :class="`d-flex flex-row pt-2 px-2 pb-0 ${(previewing) ? '' :'configurable-form-component draggable-handle'}`">
                                <div v-if="!previewing" class="d-inline align-self-top">
                                    <b-button
                                        @click="toggleEditing(comp)"
                                        :size="comp.size"
                                        :variant="(comp.editingOptions) ? 'info' : 'secondary'"
                                    >
                                        <i class="material-icons drop-down align-middle">settings</i>
                                    </b-button >
                                </div>
                                <div class="d-inline ml-3 flex-fill">
                                    <component
                                        :is="comp.type"
                                        :dataName="comp.dataName"
                                        :inputLabel="comp.inputLabel"
                                        :value="comp.value"
                                        :required="comp.required"
                                        :readOnly="comp.readOnly"
                                        :validator="(comp.validator != null) ? new RegExp(comp.validator ,'') : undefined"
                                        :multiSelect="comp.multiSelect"
                                        :options="(comp.type == 'nested-child-forms') ? [] : comp.options"
                                        :apiEndpoint="comp.apiEndpoint"
                                        :latitude="comp.latitude"
                                        :longitude="comp.longitude"
                                        :latitudeLabel="comp.latitudeLabel"
                                        :longitudeLabel="comp.longitudeLabel"
                                        :componentName="comp.componentName"
                                        :threeState="comp.threeState"
                                        :parentFormID="localFormID"
                                        :size="comp.size"
                                        :formID="comp.formID"
                                        :isSite="comp.isSite"
                                        :sendForm="null"
                                        :runOnDiscard="null"
                                        :backOnDiscard="null"
                                    ></component>
                                </div>
                            </div>
                            <b-collapse v-if="!previewing" class="form-component-configurator" v-model="comp.editingOptions">
                                <b-row class="px-2 pt-2">
                                    <b-col xl="8" md="12">
                                        <b-row>
                                            <b-col cols="12">
                                                <b-form-group 
                                                    :id="'formbuilder-config-label-' + index + '-' + comp.dataName"
                                                    label-cols-sm="4"
                                                    label-cols-lg="3"
                                                    description="Required"
                                                    label="Field Label"
                                                    label-class="align-self-center"
                                                    :label-for="'formbuilder-config-input-' + index + '-' + comp.dataName"
                                                >
                                                    <b-form-input 
                                                        :id="'formbuilder-config-input-' + index + '-' + comp.dataName"
                                                        type="text"
                                                        v-model="comp.inputLabel"
                                                        :required="true"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <!-- START - Components That Allow Size Alterations -->
                                            <b-col cols="12">
                                                <b-form-group 
                                                    :id="'formbuilder-config-size-label-' + index + '-' + comp.dataName"
                                                    label-cols-sm="4"
                                                    label-cols-lg="3"
                                                    description="Required"
                                                    label="Size"
                                                    label-class="align-self-center"
                                                    :label-for="'formbuilder-config-size-' + index + '-' + comp.dataName"
                                                >
                                                    <b-form-select  
                                                        :id="'formbuilder-config-size-' + index + '-' + comp.dataName"
                                                        :options="sizeOptions"
                                                        v-model="comp.size"
                                                        :required="true"
                                                    ></b-form-select>
                                                </b-form-group>
                                            </b-col>
                                            <!-- END - Components That Allow Size Alterations -->
                                            <!-- START - Child Form Selection -->
                                            <b-col cols="12" v-if="comp.type == 'nested-child-forms'">
                                                <b-form-group 
                                                    :id="'formbuilder-config-formid-label-' + index + '-' + comp.dataName"
                                                    label-cols-sm="4"
                                                    label-cols-lg="3"
                                                    description="Required"
                                                    label="Select A Child Form"
                                                    label-class="align-self-center"
                                                    :label-for="'formbuilder-config-formid-' + index + '-' + comp.dataName"
                                                >
                                                    <b-form-select  
                                                        :id="'formbuilder-config-formid-' + index + '-' + comp.dataName"
                                                        :options="formOptionsByType[comp.options.child_is]"
                                                        v-model="comp.formID"
                                                        :required="true"
                                                    >
                                                        <template #first>
                                                            <b-form-select-option :value="null">Not Specified - Use Default For User (Recommended)</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                </b-form-group>
                                            </b-col>
                                            <!-- START - Child Form Selection -->
                                            <!-- START - GPS INPUT ONLY Config Fields -->
                                            <b-col cols="12" v-if="comp.type == 'gps-input'">
                                                <b-form-group 
                                                    :id="'formbuilder-config-label-gps-latitude-' + index + '-' + comp.dataName"
                                                    label-cols-sm="4"
                                                    label-cols-lg="3"
                                                    description="Required"
                                                    label="Latitude Label"
                                                    label-class="align-self-center"
                                                    :label-for="'formbuilder-config-gps-latitude-input-' + index + '-' + comp.dataName"
                                                >
                                                    <b-form-input 
                                                        :id="'formbuilder-config-gps-latitude-input-' + index + '-' + comp.dataName"
                                                        type="text"
                                                        v-model="comp.latitudeLabel"
                                                        :required="true"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="12" v-if="comp.type == 'gps-input'">
                                                <b-form-group 
                                                    :id="'formbuilder-config-label-gps-longitude-' + index + '-' + comp.dataName"
                                                    label-cols-sm="4"
                                                    label-cols-lg="3"
                                                    description="Required"
                                                    label="Longitude Label"
                                                    label-class="align-self-center"
                                                    :label-for="'formbuilder-config-gps-longitude-input-' + index + '-' + comp.dataName"
                                                >
                                                    <b-form-input 
                                                        :id="'formbuilder-config-gps-longitude-input-' + index + '-' + comp.dataName"
                                                        type="text"
                                                        v-model="comp.longitudeLabel"
                                                        :required="true"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <!-- END - GPS INPUT ONLY Config Fields -->
                                        </b-row>
                                    </b-col>
                                    <b-col xl="4" md="12">
                                        <b-row>
                                            <b-col cols="12">
                                                <b-form-checkbox
                                                    switch
                                                    :id="`formbuilder-config-read-only-${index}-${comp.dataName}`"
                                                    v-model="comp.readOnly"
                                                    :name="`formbuilder-config-read-only-${index}-${comp.dataName}`"
                                                    :value="true"
                                                    :unchecked-value="false"
                                                    @change="checkboxReadOnlyToggled(comp)"
                                                    :disabled="comp.disableReadOnly"
                                                >
                                                    Read Only
                                                </b-form-checkbox>
                                            </b-col>
                                            <b-col cols="12">
                                                <b-form-checkbox
                                                    switch
                                                    :id="`formbuilder-config-required-${index}-${comp.dataName}`"
                                                    v-model="comp.required"
                                                    :name="`formbuilder-config-required-${index}-${comp.dataName}`"
                                                    :value="true"
                                                    :unchecked-value="false"
                                                    @change="checkboxRequiredToggled(comp)"
                                                    :disabled="comp.disableRequired"
                                                >
                                                    Required
                                                </b-form-checkbox>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>  
                            </b-collapse>
                        </div>
                    </draggable>
                    
                </b-form>
            </div>
            
            
        </div>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js'); 
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');
const _ = require('underscore');

// Vue Libraries
import draggable from 'vuedraggable'

// Form Components
import RegexText from '../../components/FormTextRegexInput.vue'
import TextArea from '../../components/FormTextAreaInput.vue'
import PassedSelect from '../../components/FormSelectPassedValuesInput.vue'
import APISelect from '../../components/FormSelectFetchAPIInput.vue'
import Decimal2x2Input from '../../components/FormDecimalTwoByTwoInput.vue'
import DecimalInput from '../../components/FormDecimalInput.vue'
import IntegerInput from '../../components/FormIntegerInput.vue'
import GPSInput from '../../components/FormGPSInput.vue'
import PhoneInput from '../../components/FormPhoneInput.vue'
import EmailInput from '../../components/FormEmailInput.vue'
import FormArrayInput from '../../components/FormArrayEngine.vue'
import RadioInput from '../../components/FormRadioInput.vue'
import BoolInput from '../../components/FormBooleanInput.vue'
import AddressInput from '../../components/FormAddressComponent.vue'
import AddressSimpleInput from '../../components/FormAddressSimpleComponent.vue'
import NestedForm from '../../components/NestedChildrenForms.vue'
import EventList from '../../components/FormEventListHistory.vue'
import IssueFulcrum from '../../components/subcomponents/IssueToFulcrumModal.vue'
import FlagList from '../../components/FormFlagList.vue'
import SubmitDiscard from '../../components/FormSubmitDiscard.vue'
import SectionHeader from '../../components/FormSectionHeader.vue'
import FormImage from '../../components/FormImageWithLabel.vue'
import Image from '../../components/subcomponents/Image.vue';
import ChangeParent from '../../components/subcomponents/ChangeParent.vue'
import AdvancedActionsDropDown from '../../components/subcomponents/AdvActionsDropDown.vue'

export default {
    name: 'formbuilder',
    components:{
        draggable,
        'text-input': RegexText,
        'decimal-2x2-input': Decimal2x2Input,
        'text-area': TextArea,
        'passed-select': PassedSelect,
        'api-select': APISelect,
        'decimal-input': DecimalInput,
        'integer-input': IntegerInput,
        'gps-input': GPSInput,
        'phone-input': PhoneInput,
        'email-input': EmailInput,
        'array-input': FormArrayInput,
        'radio-select': RadioInput,
        'bool-select': BoolInput,
        'address-input': AddressInput,
        'address-simple-input': AddressSimpleInput,
        'nested-child-forms': NestedForm,
        'event-list': EventList,
        'issue-to-fulcrum-modal': IssueFulcrum,
        'flag-list': FlagList,
        'submit-discard': SubmitDiscard,
        'section-header': SectionHeader,
        'hover-image-with-modal': Image,
        'change-parent': ChangeParent,
        'adv-opt-dd': AdvancedActionsDropDown,
        'form-image': FormImage
    },
    props:{
        localFormID: {
            type: String,
            default: () =>{ return uuidv4(); }
        },
        activeFormID: {
            type: String,
            default: () =>{ return null; }
        }
    },
    data(){
        return{
            // Initialization Stuff
            requestFormInspects: true,
            waitingForActionTypeSelection: true,
            selectedActionType: null,
            tinyMCEInitProm: null,
            // Load Stuff
            allLoadableForms: [],
            formLoadSelected: null,
            loadedFields: [],
            // Save and Load
            formID: null,
            formName: null,
            formDescription: null,
            formInspects: null,
            filterFor: 'All',
            sidebarOpen: true,
            previewing: false,
            discard: [],
            utilities:[
                {
                    fbf_id: "39dc93d3-5cc1-43f6-a940-fea0a3556005",
                    type: "section-header",
                    dataName: null,
                    inputLabel: "Section Header",
                    value: null,
                    parent: null,
                    required: false,
                    readOnly: true,
                    multiSelect: false,
                    apiEndpoint: null,
                    stacked: false,
                    size: null,
                    threeState: false,
                    componentName: null,
                    formID: null,
                    // start custom stuff
                    editingOptions: false,
                    disableRequired: true, // Should start TRUE if readOnly
                    disableReadOnly: true, // Should start TRUE if required
                }
            ],
            fields:[],
            inForm:[],
            orderedFields:{},
            availableGroups: [],
            sizeOptions: [
                { text: "Small", value: "sm"},
                { text: "Medium", value: "md"},
                { text: "Large", value: "lg"}
            ],
            inspectOptions: [
                { text: "Device", value: "devices"},
                { text: "Connection", value: "connections"},
                { text: "Site", value: "sites"},
                { text: "Customer", value: "customers"}
            ],
            formOptionsByType: {},
            // Stuff Required To Make Vuex Happy
            formDef: null,
            dragging: false,
            // Title Stuff
            tinymce: null,
            subjectInitValue: null,
            startSubjectContentValue: '',
        }
    },
    methods:{
        exitPage(){
            this.$router.go(-1);
        },
        completedCreateSelection(){
            if(this.formInspects == 'customers'){
                this.filterFor = 'Customer';
            }else if(this.formInspects == 'sites'){
                this.filterFor = 'Site';
            }else if(this.formInspects == 'connections'){
                this.filterFor = 'Connection';
            }else if(this.formInspects == 'devices'){
                this.filterFor = 'Device';
            }else{
                this.filterFor = 'All'
            }

            this.fetchFields();
            this.requestFormInspects = false;
            this.ready();
        },
        completedUpdateSelection(){
            this.loadForm();
            this.requestFormInspects = false;
            this.ready();
        },
        togglePreview(){
            this.previewing = !this.previewing;
        },
        setAllSize(sizeRequested){
            this.inForm.forEach((field)=>{
                field.size = sizeRequested;
            })
            this.fields.forEach((field)=>{
                field.size = sizeRequested;
            })
            this.utilities.forEach((field)=>{
                field.size = sizeRequested;
            })
        },
        ready(){
            this.tinymce = window.tinymce;
            var useDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

            this.subjectInitValue = {
                menubar: '',
                skin: useDarkMode ? 'oxide-dark' : 'oxide',
                content_css: useDarkMode ? 'dark' : 'default',
                height: 100,
                selector: 'textarea#tinymce-subject-editor',
                noneditable_noneditable_class: 'mceNonEditable',
                toolbar: 'variables',
                extended_valid_elements: 'ejsinline',
                custom_element: 'ejsinline',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } ejsinline{ display: none; } span.ejshidden{ display: none; }',
            };
            this.subjectInitValue.setup = (editor)=>{
                editor.on('init', (e)=>{
                    editor.setContent(this.startSubjectContentValue);
                })
                var $ = tinymce.dom.DomQuery;
                var nonEditableClass = editor.getParam('noneditable_noneditable_class', 'mceNonEditable');
                editor.on('BeforeExecCommand', (e)=>{
                    $(editor.getBody()).find('.' + nonEditableClass).attr('contenteditable', null);
                })
                editor.on('ExecCommand',(e)=>{
                    $(editor.getBody()).find('.' + nonEditableClass).attr('contenteditable', false);
                })
                editor.ui.registry.addMenuButton('variables', {
                    text: 'Variables',
                    fetch: (callback)=> {
                        var items = [];
                        this.inForm.filter((inForm)=>{ return inForm.type != "gps-input" && inForm.type != "nested-child-forms"}).forEach((itm)=>{
                            var child = {
                                type: 'menuitem',
                                text: itm.inputLabel,
                                onAction: ()=>{
                                    if(itm.type == "gps-input" || itm.type == "nested-child-forms"){
                                        editor.insertContent(`<span class="mceNonEditable"><%# ${itm.inputLabel} Not Available In Subject %></span>`);
                                    }else{
                                        editor.insertContent(`<span class="mceNonEditable"><span class="ejshidden"><%= data["${itm.fbf_id}"].value %></span><%# ${itm.inputLabel} %></span>`);
                                    }
                                    
                                }
                            }
                            items.push(child);
                        })
                        callback(items);
                    }
                });
            };

            this.tinyMCEInitProm = this.tinymce.init(this.subjectInitValue)
        },
        sortFieldsOrdinal(){
            this.fields = this.fields.sort((a, b)=>{
                if(a.ordinal_position < b.ordinal_position){
                    return -1;
                }
                if(a.ordinal_position > b.ordinal_position){
                    return 1;
                }
                return 0;
            })
        },
        checkForDiscards(){
            if(this.discard.length > 0){
                this.discard.forEach((discarded)=>{
                    if(discarded.type == 'section-header'){
                        // Just remove this discarded item
                        this.discard = this.discard.filter((fld)=>{ return fld != discarded; });
                    }else{
                        discarded.editingOptions = false;
                        this.fields.push(discarded);
                        this.discard = this.discard.filter((fld)=>{ return fld != discarded; });
                    }
                    
                })
                this.sortFieldsOrdinal();
            }
        },
        checkboxReadOnlyToggled(field){
            // If Read Only is Toggled, Then Required is false & Cannot Be Toggled
            console.log(field.readOnly);
            if(field.readOnly == true){
                field.required = false;
                field.disableRequired = true;
            }else{
                field.disableRequired = false;
            }
        },
        checkboxRequiredToggled(field){
            // If Required is Toggled, Then Read Only is false & Cannot Be Toggled
            if(field.required){
                field.readOnly = false;
                field.disableReadOnly = true;
            }else{
                field.disableReadOnly = false;
            }
        },
        toggleEditing(field){
            field.editingOptions = !field.editingOptions;
        },
        toggleNav(){
            this.sidebarOpen = !this.sidebarOpen;
        },
        moveToForm(field){
            var match = this.fields.find((fld)=>{ return fld == field; })
            if(match != undefined){
                field.editingOptions = false;
                this.inForm.push(field);
                this.fields = this.fields.filter((fld)=>{ return fld != field; });
            }else{
                // Do Nothing, We Cannot Move The Same Item Into The Form Twice
            }
        },
        sortInFormFields(){
            this.inForm = this.inForm.sort((a, b)=>{
                if(a.ordinal_position < b.ordinal_position){
                    return -1;
                }
                if(a.ordinal_position > b.ordinal_position){
                    return 1;
                }
                return 0;
            })
        },
        copyResusableToForm(field){
            field.editingOptions = false;
            this.inForm.push(_.clone(field));
        },
        calculateParentOptions(){
            var groups = [];
            this.fields.forEach((fld)=>{
                if(groups.indexOf(fld.uiGroup) == -1){
                    groups.push(fld.uiGroup);
                }
            })
            this.availableGroups = [];
            groups.forEach((par)=>{
                var displayName = par;
                this.availableGroups.push({ dataName: par, name: displayName })
            })
        },
        fetchFields(){
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/forms/builder/fields.json?filter=${this.filterFor}`)
            .then((response)=>{
                console.log(response);
                this.fields = [];
                this.fields = response.data.result;
                //var availableFields = response.data.result;
                var moved = [];
                // We now need to add the loaded form fields on the loaded form into the builder stage
                this.loadedFields.forEach((loadedField)=>{
                    if(loadedField.fbf_id == "39dc93d3-5cc1-43f6-a940-fea0a3556005"){
                        // Its a reusable, handle it special
                        this.copyResusableToForm(loadedField);
                    }else{
                        // Its any other field
                        // Which means we need to grab the corresponding form builder field
                        var match = _.find(this.fields, (fld)=>{ return fld.fbf_id == loadedField.fbf_id; })
                        if(match == undefined){
                            if(moved.indexOf(loadedField.fbf_id) != -1){
                                // This is fine, we can move on with our lives
                            }else{
                                console.log(`FBF ID: ${loadedField.fbf_id} Not Available In Fields, Was Expected`)
                            }
                        }else{
                            // add in any data from the loaded form field
                            match.readOnly = loadedField.readOnly;
                            match.multiSelect = loadedField.multiSelect;
                            match.stacked = loadedField.stacked;
                            match.threeState = loadedField.threeState;
                            match.size = loadedField.size;
                            match.inputLabel = loadedField.inputLabel;
                            match.required = loadedField.required;
                            match.formID = loadedField.formID;
                            match.ordinal_position = loadedField.ordinal_position;

                            if(_.has(loadedField, 'longitudeLabel')){
                                match.longitudeLabel = loadedField.longitudeLabel;
                            }
                            if(_.has(loadedField, 'latitudeLabel')){
                                match.latitudeLabel = loadedField.latitudeLabel;
                            }
                            // Lets keep track of the FBF IDs we've already moved
                            moved.push(match.fbf_id)

                            // and move the form builder field to the stage
                            this.moveToForm(match)
                        }
                        
                    }
                })



                // // Grab the fbf_ids for the loaded fields
                // var allFBFIDs = _.pluck(this.loadedFields, 'fbf_id');

                

                // var uniqueFBFIDs = _.uniq(allFBFIDs, false, (item)=>{ 
                //     // This is such a terrible hack, solve this problem by handling the duplicate fbf_ids that occur when addresses are pushed into the form, in the part that pushes them into the form
                //     if(item === "39dc93d3-5cc1-43f6-a940-fea0a3556005"){
                //         return uuidv4();
                //     }else{
                //         return item;
                //     } 
                // }); 

                // uniqueFBFIDs.forEach((fbfID, index)=>{
                //     // TODO: fix that terrible junk uniqueFBFIDs behavior by implementing a fix for address handling here
                //     if(fbfID != "39dc93d3-5cc1-43f6-a940-fea0a3556005"){
                //         var loadedMatch = this.loadedFields[index];
                //         var match = _.find(this.fields, (fld)=>{ return fld.fbf_id == fbfID; })
                        
                        
                //         debugger;
                //         if(_.has(loadedMatch, 'longitudeLabel')){
                //             match.longitudeLabel = loadedMatch.longitudeLabel;
                //         }
                //         if(_.has(loadedMatch, 'latitudeLabel')){
                //             match.latitudeLabel = loadedMatch.latitudeLabel;
                //         }
                //         this.moveToForm(match)
                //     }else{
                //         var loadedMatch = this.loadedFields[index];
                //         this.copyResusableToForm(loadedMatch);
                //     }
                // })
                
                // console.log(`Builder Fields`)
                // console.log(this.fields);
                this.calculateParentOptions();
                this.sortFieldsOrdinal();
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Failed To Fetch Form Builder Fields', 'Try logging in again', 'danger')
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Failed To Fetch Form Builder Fields', 'Failed due to EPC Restrictions', 'danger')
                }else{
                    butils.createToast(this, 'Failed To Fetch Form Builder Fields', 'Something went wrong, check the console for details.', 'danger')
                    console.log(err)
                }
            })
        },
        fetchFormList(){
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/forms/list.json`)
            .then((response)=>{
                var allForms = response.data.result;
                this.allLoadableForms = [];
                this.formOptionsByType = {};
                allForms.forEach((form)=>{
                    if(form.is_custom_form){
                        this.allLoadableForms.push({ value: form.id, text: `(${form.form_inspects}) ${form.name} (${form.notes})` })
                    }
                    if(_.has(this.formOptionsByType, form.form_inspects)){
                        this.formOptionsByType[form.form_inspects].push({ value: form.id, text: `${form.name} (${form.notes})` });
                    }else{
                        this.formOptionsByType[form.form_inspects] = [];
                        this.formOptionsByType[form.form_inspects].push({ value: form.id, text: `${form.name} (${form.notes})` });
                    }
                })
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Failed To Fetch Available Form List', 'Try logging in again', 'danger')
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Failed To Fetch Available Form List', 'Failed due to EPC Restrictions', 'danger')
                }else{
                    butils.createToast(this, 'Failed To Fetch Available Form List', 'Something went wrong, check the console for details.', 'danger')
                    console.log(err)
                }
            })
        },
        packTitleEJS(){
            let subjectTmp = this.tinymce.get("tinymce-subject-editor").getContent();
            subjectTmp = subjectTmp.replace(/&lt;%=/g,'<%=');
            subjectTmp = subjectTmp.replace(/&lt;%/g,'<%');
            subjectTmp = subjectTmp.replace(/%&gt;/g,'%>');
            subjectTmp = subjectTmp.replace('<p>','');
            subjectTmp = subjectTmp.replace('</p>','');
            return subjectTmp;
        },
        saveCopy(){
            this.formID = null;
            this.prepareForSave();
        },
        prepareForSave(){
            var form = {
                meta: {
                    formID: this.formID,
                    name: this.formName,
                    notes: this.formDescription,
                    titleEJS: this.packTitleEJS(),
                    type: "edit",
                    showEventHist: true,
                    formInspects: this.formInspects
                },
                fields: []
            };
            // TODO: Validate Title Info

            this.inForm.forEach((field, idx)=>{
                field.ordinal_position = idx;
                form.fields.push(field);
            })
            butils.instance.post(`${process.env.VUE_APP_API_BASE_URL}/forms/builder/form.json`, {form: form})
            .then((response)=>{
                console.log(response);
                this.formID = response.data.result.formID;
                butils.createToast(this, 'Form Saved Successfully', `You're ready to go!`, 'success', 20);
            })
            .catch((err)=>{
                if(butils.isError403(err)){
                    butils.createToast(this, 'Form Save Failed', `Request was rejected by EPC, contact your administrator to receive rights to create or update forms.`, 'danger', 90);
                }else if(butils.isError401(err)){
                    butils.createToast(this, 'Form Save Failed', `Authentication Failed, Try Logging In Again.`, 'danger', 90);
                }else{
                    butils.createToast(this, 'Form Save Failed', `Check the console for details`, 'danger', 90);
                    console.log(err);
                }
            })
        },
        loadForm(){
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/forms/builder/load/${this.formLoadSelected}.json`)
            .then((response)=>{
                // console.log(response);
                butils.updateWindowPathAndSetTitle(this, `/home/formbuilder/${response.data.result.meta.formID}`, `Editing Form: ${response.data.result.meta.name} - Form Builder`)
                this.inForm = [];
                this.loadedFields = response.data.result.fields;
                this.tinyMCEInitProm.then(()=>{
                    this.tinymce.get("tinymce-subject-editor").setContent(response.data.result.meta.title);
                })
                this.formID = response.data.result.meta.formID;
                this.formName = response.data.result.meta.name;
                this.formDescription = response.data.result.meta.notes;
                this.formInspects = response.data.result.meta.formInspects;
                if(this.formInspects == 'customers'){
                    this.filterFor = 'Customer';
                }else if(this.formInspects == 'sites'){
                    this.filterFor = 'Site';
                }else if(this.formInspects == 'connections'){
                    this.filterFor = 'Connection';
                }else if(this.formInspects == 'devices'){
                    this.filterFor = 'Device';
                }else{
                    this.filterFor = 'All'
                }
                this.fetchFields();
                butils.createToast(this, 'Form Loaded', `Form Loaded`, 'info', 5);
            })
            .catch((err)=>{
                console.log(err);
                if(butils.isError403(err)){
                    butils.createToast(this, 'Form Load Failed', `Request was rejected by EPC, contact your administrator to receive rights to create or update forms.`, 'danger', 90);
                }else if(butils.isError401(err)){
                    butils.createToast(this, 'Form Load Failed', `Authentication Failed, Try Logging In Again.`, 'danger', 90);
                }else{
                    butils.createToast(this, 'Form Load Failed', `Check the console for details`, 'danger', 90);
                    console.log(err);
                }
            })
        }
    },
    watch:{

    },
    computed: {
        dragOptions() {
        return {
            animation: 200,
            group: "description",
            disabled: false,
            ghostClass: "ghost"
        };
        }
    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){
        butils.setWindowTitle('Form Builder');
        this.formDef = {
            meta: {
                name: '',
                title: '',
                showEventHist: false,
                formParentKey: null
            },
            fields: {}
        };
        
        
    },
    mounted(){
        this.ready();
        if(this.activeFormID != null){
            this.requestFormInspects = false;
            this.formLoadSelected = this.activeFormID;
            this.loadForm();
        }
        this.fetchFormList();
        this.$store.commit('initForm',{ localFormID: this.localFormID, meta: this.formDef.meta });
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){
        this.$store.commit('clearForm', {localFormID: this.localFormID});
    },
    destroyed(){

    }
}
</script>

<style scoped>
div.form-group{
    margin-bottom: 0rem;
}
.smaller-mdi{
    font-size: 1rem;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
div.drag-drop-box{
    border-style: dotted;
}
div.configurable-form-component{
    background-color: #ebf5ff;
}
div.form-component-configurator{
    background-color: #eef2f6;
    border-style: groove;
}
div.hoverable-add:hover{
    color: darkgray;
}
#full-form{
    overflow-y: scroll;
    overflow-x: hidden;
    flex: 1;
}
#lb-table{
    overflow-y: scroll;
    overflow-x: scroll;
    max-height: calc(100vh - 56px);
}
.scrollable-veritically{
    height: calc(100vh - 152px);
    overflow-y: auto;
}
#fit-window{
    height: calc(100vh - 56px);
    width: calc(100vw - 30px);
    display: flex;
    flex-flow: row;
    /* overflow-y: scroll;
    overflow-x: scroll; */
    /* margin-left: -15px; */
    /* margin-right: -15px; */
}
#table-holder{
    display: flex;
    flex-flow: column;
    max-height: calc(100vh - 56px);
    transition: all 0.3s;
    /* white-space: nowrap; */
}
#table-holder.lb-th-sb-open{
    width: calc(100% - 315px);
}
#table-holder.lb-th-sb-closed{
    width: 100%;
}
.lb-table-th{
    white-space: nowrap;
}
.lb-table-td{
    overflow:visible;
    white-space: nowrap;
}
#lb-sidebar{
    background-color: hsl(0, 0%, 80%);
    position: fixed;
    top: 56px;
    min-height: calc(100vh - 56px);
    z-index: 1003;
    transition: all 0.3s;
}
.lb-sb-nav-open{
    width: 300px;
    left: 0;
}
.lb-sb-nav-closed{
    width: 20px;
    left: -300px;
}
#lb-sidebar-container{
    display: inline-block;
    position:sticky;
    top: 56px;
    z-index: 1002;
    margin-left: -15px;
    min-height: calc(100vh - 56px);
    line-height: calc(100vh - 56px);
    transition: all 0.3s;
}
div.lb-sb-cont-closed{
    min-width: 20px;
}
div.lb-sb-cont-open{
    min-width: 335px;
}
.collapse-control-btn{
    text-align: right;
}
.xpander-icon{
    background-color: grey;
    padding-left: 1px;
    padding-top: 30px;
    padding-right: 5px;
    padding-bottom: 30px;
    color: white;
    border-radius: 0px 30px 30px 0px;

}
.xpander-icon-expanded{
    padding-left: 10px;
    padding-top: 30px;
    padding-right: 5px;
    padding-bottom: 30px;
}
.xpander-icon:hover{
    cursor:pointer;
    background-color: darkgrey;
}
.centered-expand-control{
    margin: auto;
    text-align: right;
}
#field-list{
    width: 15vw;
    overflow-y: scroll;
}

#mapContainer{
    position: relative;
    width: 100%;
    min-height: 20vh;
    height: 40vh;
    resize: vertical;
}
.even-odd-checkbox-rows:nth-child(even) {
    background-color: #cccccc
}
.even-odd-checkbox-rows:nth-child(odd) {
    background: #999999
    }
.header-available-options-list{
    overflow-y: scroll;
    max-height: 15vh;
}
.header-dropdown{
    min-width: 15vw;
}
th.green-th{
    color: green;
}


.hoverable:hover{
    background-color: lightgreen;
}
.hoverable{
    cursor:pointer;
}
.clickable{
    cursor: pointer;
}
.movable{
    cursor: move;
}
.expanded-child {
  border-left: 4px dotted black;
}
.scrollable{
    overflow-x: scroll;
    white-space: nowrap;
}
.tableheadernowrapping{
    white-space: nowrap;
}
th.delimited{
    border-left: 1px;
    border-left-style: solid;
    border-left-color: white;
}
div.drag-indicator{
    color: white;
    position: relative;
    top: 0;
    left: 0;
    border-left-color: lightgray;
    border-right-color:lightgray;
    border-left-width: 2px;
    border-right-width: 2px;
    border-left-style: solid;
    border-right-style: solid;
}
.header-inline{
    display: inline-block;
}
.scroll-over{
    height: 100%;
    overflow-y: scroll;
}
.filter-combinator-select{
    width: 10vw;
}
.text-match-drop-down{
    font-size: 1.25em;
    line-height: 2em;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
    display: none;
}
.child-form-head{
    width: 100%;
}
.more-than-row{
    background-color: yellow;
}
div.act-like-button{
    background-color: black;
}
div.act-like-button:hover{
    background-color: darkgray;
    color: gold;
}
.sticky-header{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0px;
    background-color: white;
    z-index: 1;
}

</style>